<template>
  <el-collapse v-model="filter" @change="handleChange">
    <el-collapse-item title="Фильтр" name="p1">
        <template v-slot:title>
          <span>
            <span>Фильтр</span>
            <el-button class="ml-5"
                plain
                size="mini"
                type="default" 
                @click.stop="clearFilters" 
                style="font-family: Montserrat-Medium !important;">
              Очистить фильтры
            </el-button>
          </span>
        </template>
      <el-row :gutter="5" class="mt-0 filter">
        <el-col :span="6" class="ml-2 filter--container">Наименование:</el-col>
        <el-col :span="6" class="ml-0 filter--container">Энергосистема:</el-col>
        <el-col :span="6" class="ml-0 filter--container">Энергообъект:</el-col>
        <el-col :span="6" class="ml-0 filter--container">Владелец / эксплуатирующая организация:</el-col>
      </el-row>
      <el-row class="m-0 filter">
        <el-col :span="6" class="ml-2 filter--container">
          <!-- <span class="mr-2 mt-1">Наименование:</span> -->
          <remote-search-input size="small"
            v-model="filterModel.nameFilter"
            searchUrl="/api/secondEquipmentPanels/SearchByName"
            return-prop="value"
            :start-length=3
            :multiple=false
          />
        </el-col>

        <el-col :span="6" class="ml-2 filter--container">
          <!-- <span class="mr-2 mt-1">Энергосистема:</span> -->
          <!-- <remote-search-input size="small"
              v-model="filterModel.eoRegionFilter"
              searchUrl="/api/regions/SearchByName"
              :allow-create=false
              :multiple=true
              :start-length=3
              returnProp="id"
            /> -->
          <el-select multiple filterable clearable size="small"
                     ref="eoRegionFilterSelector"
                     :collapse-tags="filterModel.eoRegionFilter.length > 5"
                     v-model="filterModel.eoRegionFilter"
                     popper-class="search-select">
            <div class="pl-3">
              <el-button type="text" size="small"
                         @click="filterModel.eoRegionFilter = getSelectedIdsByName(operationalZones, $refs.eoRegionFilterSelector.query)">
                Выбрать всё
              </el-button>
            </div>
            <el-option
              v-for="item in operationalZones"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-col>

        <el-col :span="6" class="ml-2 filter--container">
          <!-- <span class="mr-2 mt-1">Энергообъект:</span> -->
          <remote-search-input size="small"
            v-model="filterModel.eoIdFilter"
            searchUrl="/api/SecondEquipmentPanels/SearchEnergyObjectsByName"
            :allow-create=false
            :disabled="filterModel.skipEoObject"
            return-prop="value"
            :start-length=3
            :multiple=true
          />
        </el-col>

        <el-col :span="6" class="ml-2 filter--container">
          <!-- <span style="min-width:13rem;">Владелец / эксплуатирующая организация:</span> -->
          <remote-search-input
            v-model="filterModel.ownerOrganizationsFilter"
            searchUrl="/api/Organizations/SearchByOwner"
            :start-length=1
            :allow-create=false
            :multiple=true
            returnProp="id"
          />
        </el-col>
      </el-row>

      <el-row :gutter="5" class="mt-2 filter">
        <el-col :span="6" class="ml-2 filter--container">Диспетчерское управление:</el-col>
        <el-col :span="6" class="ml-0 filter--container">Диспетчерское ведение:</el-col>
        <el-col :span="6" class="ml-0 filter--container">Технологическое управление:</el-col>
        <el-col :span="6" class="ml-0 filter--container">Технологическое ведение:</el-col>
      </el-row>

      <el-row class="m-0 filter">
        <el-col :span="6" class="ml-2 filter--container">
          <!-- <span class="mr-2 mt-1">Диспетчерское управление:</span> -->
          <!-- <remote-search-input size="small"
            v-model="filterModel.duOrganizationName"
            searchUrl="/api/organizations/SearchByNameAndRole?roleName=Диспетчерское управление"
            :allow-create=false
            :start-length=3
            :multiple="true"
          /> -->
          <el-select multiple filterable clearable size="small"
                     ref="duOrganizationsSelector"
                     :collapse-tags="filterModel.duOrganizationName.length > 2"
                     v-model="filterModel.duOrganizationName"
                     popper-class="search-select">
            <div class="pl-3">
              <el-button type="text" size="small"
                         @click="filterModel.duOrganizationName = getSelectedValues(duOrganizations, $refs.duOrganizationsSelector.query, (item) => (item.value), (item) => (item.value))">
                Выбрать всё
              </el-button>
            </div>
            <el-option v-for="item in duOrganizations"
                       :key="item.id"
                       :label="item.value"
                       :value="item.value">
            </el-option>
          </el-select>
        </el-col>

        <el-col :span="6" class="ml-2 filter--container">
          <!-- <span class="mr-2 mt-1">Диспетчерское ведение:</span> -->
          <!-- <remote-search-input size="small"
            v-model="filterModel.dvOrganizationName"
            searchUrl="/api/organizations/SearchByNameAndRole?roleName=Диспетчерское ведение"
            :allow-create=false
            :start-length=3
            :multiple="true"
          /> -->
          <el-select multiple filterable clearable size="small"
                     ref="dvOrganizationsSelector"
                     :collapse-tags="filterModel.dvOrganizationName.length > 2"
                     v-model="filterModel.dvOrganizationName"
                     popper-class="search-select">
            <div class="pl-3">
              <el-button type="text" size="small"
                         @click="filterModel.dvOrganizationName = getSelectedValues(dvOrganizations, $refs.dvOrganizationsSelector.query, (item) => (item.value), (item) => (item.value))">
                Выбрать всё
              </el-button>
            </div>
            <el-option v-for="item in dvOrganizations"
                       :key="item.id"
                       :label="item.value"
                       :value="item.value">
            </el-option>
          </el-select>
        </el-col>

        <el-col :span="6" class="ml-2 filter--container">
          <!-- <span class="mr-2 mt-1">Технологическое управление:</span> -->
          <!-- <remote-search-input size="small"
            v-model="filterModel.techAdministrationOrganizationsFilter"
            searchUrl="/api/organizations/SearchByNameAndRole?roleName=Технологическое управление"
            :allow-create=false
            :multiple="true"
            returnProp="id"
          /> -->
          <el-select multiple filterable clearable size="small"
                     ref="taOrganizationsSelector"
                     :collapse-tags="filterModel.techAdministrationOrganizationsFilter.length > 2"
                     v-model="filterModel.techAdministrationOrganizationsFilter"
                     popper-class="search-select">
            <div class="pl-3">
              <el-button type="text" size="small"
                         @click="filterModel.techAdministrationOrganizationsFilter = getSelectedValues(techAdministrationOrganizations, $refs.taOrganizationsSelector.query, (item) => (item.value), (item) => (item.id))">
                Выбрать всё
              </el-button>
            </div>
            <el-option v-for="item in techAdministrationOrganizations"
                       :key="item.id"
                       :label="item.value"
                       :value="item.id">
            </el-option>
          </el-select>
        </el-col>

        <el-col :span="6" class="ml-2 filter--container">
          <!-- <span class="mr-2 mt-1">Технологическое ведение:</span> -->
          <!-- <remote-search-input size="small"
            v-model="filterModel.techManagementOrganizationsFilter"
            searchUrl="/api/organizations/SearchByNameAndRole?roleName=Технологическое ведение"
            :allow-create=false
            :start-length=3
            :multiple="true"
            returnProp="id"
          /> -->
          <el-select multiple filterable clearable size="small"
                     ref="tmOrganizationsSelector"
                     :collapse-tags="filterModel.techManagementOrganizationsFilter.length > 2"
                     v-model="filterModel.techManagementOrganizationsFilter"
                     popper-class="search-select">
            <div class="pl-3">
              <el-button type="text" size="small"
                         @click="filterModel.techManagementOrganizationsFilter = getSelectedValues(techManagementOrganizations, $refs.tmOrganizationsSelector.query, (item) => (item.value), (item) => (item.id))">
                Выбрать всё
              </el-button>
            </div>
            <el-option v-for="item in techManagementOrganizations"
                       :key="item.id"
                       :label="item.value"
                       :value="item.id">
            </el-option>
          </el-select>
        </el-col>

      </el-row>

      <el-row :gutter="5" class="mt-0 filter">
        <el-col :span="6" class="ml-2 filter--container">Фирменное устройство РЗА:</el-col>
        <el-col :span="6" class="ml-0 filter--container">Исполнение:</el-col>
        <el-col :span="6" class="ml-0 filter--container">Группы учета:</el-col>
        <el-col :span="6" class="ml-0 filter--container">Технический учёт ДЦ:</el-col>
      </el-row>

      <el-row class="m-0 filter">
        <el-col :span="6" class="ml-2 filter--container">
          <!-- <span class="mr-2 mt-1">Фирменное устройство РЗА:</span> -->
          <remote-search-input size="small"
            v-model="filterModel.productAssetModelIdFilter"
            searchUrl="/api/productAssetModels/SearchByName"
            return-prop="value"
            :allow-create=false
            :multiple=true
          />
        </el-col>

        <el-col :span="6" class="ml-2 filter--container">
          <!-- <span class="mr-2" style="min-width:13rem;">Исполнение:</span> -->
          <el-select multiple filterable clearable size="small"
                     ref="executionsSelector"
                     :collapse-tags="filterModel.executionsFilter.length > 5"
                      v-model="filterModel.executionsFilter"
                      popper-class="search-select">
            <div class="pl-3">
              <el-button type="text" size="small"
                         @click="filterModel.executionsFilter = getSelectedValues(executions, $refs.executionsSelector.query, (item) => (item.code), (item) => (item.id))">
                Выбрать всё
              </el-button>
            </div>
            <el-option
              v-for="item in executions"
              :key="item.id"
              :label="item.code"
              :value="item.id">
            </el-option>
          </el-select>
        </el-col>

        <el-col :span="6" class="ml-2 filter--container">
          <!-- <span class="mr-2" style="min-width:13rem;">Группы учета:</span> -->
          <el-select multiple filterable clearable size="small"
                    ref="registrationGroupsSelector"
                    :collapse-tags="filterModel.registrationGroupFilter.length > 5"
                    v-model="filterModel.registrationGroupFilter">
              <div class="pl-3">
                <el-button type="text" size="small"
                          @click="filterModel.registrationGroupFilter = getSelectedIdsByName(registrationGroups, $refs.registrationGroupsSelector.query)">
                  Выбрать всё
                </el-button>
              </div>
              <el-option
                v-for="item in registrationGroups"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
        </el-col>

        <el-col :span="6" class="ml-2 filter--container">
            <!-- <span class="mr-2" style="min-width:13rem;">Технический учёт ДЦ:</span> -->
            <!-- <remote-search-input size="small"
              v-model="filterModel.technicalOrganizationsFilter"
              searchUrl="/api/organizations/SearchByNameAndRole"
              :allow-create=false
              :start-length=3
              :multiple="true"
              returnProp="id"
            /> -->
            <el-select multiple filterable clearable size="small"
                       ref="organizationsSelector"
                       :collapse-tags="filterModel.technicalOrganizationsFilter.length > 2"
                       v-model="filterModel.technicalOrganizationsFilter"
                       popper-class="search-select">
            <div class="pl-3">
              <el-button type="text" size="small"
                         @click="filterModel.technicalOrganizationsFilter = getSelectedIdsByName(organizations, $refs.organizationsSelector.query)">
                Выбрать всё
              </el-button>
            </div>
            <el-option
              v-for="item in organizations"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row :gutter="5" class="mt-0 filter">
        <el-col :span="6" class="ml-2 filter--container">Напряжение:</el-col>
        <el-col :span="6" class="ml-0 filter--container">Период ввода в эксплуатацию:</el-col>
        <el-col :span="6" class="ml-0 filter--container">Период вывода из эксплуатации:</el-col>
        <el-col :span="6" class="ml-0 filter--container">Отобр. выведенные из экспл. устройства:</el-col>
      </el-row>

      <el-row class="mt-0 filter">
        <el-col :span="6" class="ml-2 filter--container">
          <el-select multiple filterable clearable size="small"
                     ref="baseVoltageSelector"
                     :collapse-tags="filterModel.baseVoltageFilter.length > 5"
                     v-model="filterModel.baseVoltageFilter">
            <div class="pl-3">
              <el-button type="text" size="small"
                         @click="filterModel.baseVoltageFilter = getSelectedIdsByName(sortedDescBaseVoltages, $refs.baseVoltageSelector.query)">
                Выбрать всё
              </el-button>
            </div>
            <el-option
              v-for="item in sortedDescBaseVoltages"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-col>

        <el-col :span="6" class="ml-2 filter--container">
          <!-- <span class="mr-2" style="min-width:13rem;">Период ввода в эксплуатацию:</span> -->
          <el-date-picker
           size="small"
            v-model="filterModel.periodCommissioningTimeRange"
            type="daterange"
            range-separator="-"
            placeholder="дд.мм.гггг"
            format="dd.MM.yyyy"
            start-placeholder="с"
            end-placeholder="по">
          </el-date-picker>
        </el-col>
        <el-col :span="6" class="ml-2 filter--container">
          <!-- <span class="mr-2" style="min-width:13rem;">Период вывода из эксплуатации:</span> -->
          <el-date-picker
            size="small"
            v-model="filterModel.periodDecommissioningTimeRange"
            type="daterange"
            range-separator="-"
            placeholder="дд.мм.гггг"
            format="dd.MM.yyyy"
            start-placeholder="с"
            end-placeholder="по">
          </el-date-picker>
        </el-col>
        <el-col :span="6" class="ml-2 filter--container">
          <!-- <span class="mr-2" style="min-width:13rem;">Отобр. выведенные из экспл. устройства:</span> -->
          <el-select v-model="filterModel.showDecommissionedSE" size="small">
            <el-option
              v-for="item in decommissionedSEP"
              :key="item.id"
              :label="item.name"
              :value="item.code">
            </el-option>
          </el-select>
        </el-col>

      </el-row>
      <el-row :gutter="5" class="mt-0 filter">
        <el-col :span="6" class="ml-2 filter--container">Отображать объекты локальной НСИ:</el-col>
        <el-col :span="6" class="ml-0 filter--container">Показать записи без привязки к ЭО:</el-col>
        <el-col :span="6" class="ml-0 filter--container"></el-col>
        <el-col :span="6" class="ml-0 filter--container"></el-col>
      </el-row>
      <el-row class="mt-0 filter">
        <el-col :span="6" class="filter--container ml-2">
          <!-- <span class="mr-2 mt-1">Отображать объекты локальной НСИ:</span> -->
          <!-- <el-checkbox v-model="filterModel.isLocalNsiFilter"/> -->
          <el-switch style="width: 100%;"
                v-model="filterModel.isLocalNsiFilter"
                active-color="#13ce66"
                inactive-color="#ff4949"
              />
        </el-col>
        <el-col :span="6" class="ml-2 filter--container">
          <!-- <span class="mr-2">Показать записи без привязки к ЭО:</span> -->
          <!-- <el-checkbox v-model="filterModel.skipEoObject"/> -->
              <el-switch style="width: 100%;"
                v-model="filterModel.skipEoObject"
                active-color="#13ce66"
                inactive-color="#ff4949"
              />
        </el-col>
        <el-col :span="6" class="filter--container ml-2">
          <span class="mr-2 mt-1"></span>
        </el-col>
        <el-col :span="6" class="ml-2 filter--container">
          <span class="mr-2"></span>
        </el-col>
      </el-row>
      <!-- <el-row class="m-0 filter">
        <el-col :span="8" class="ml-2 filter--container">
          <el-button @click="clearFilters" type="text" style="font-family: Montserrat-Medium !important;">
            Очистить фильтры
          </el-button>
        </el-col>
      </el-row> -->
    </el-collapse-item>
  </el-collapse>
</template>

<script>
import { mapGetters } from 'vuex';
import RemoteSearchInput from '@/components/Ui/RemoteSearchInput';
import UserSettingsService from '@/service/userSettingsService';
import executions from '@/api/nsi/executions';
import organizationsApi from '@/api/nsi/organizations';
import regApi from '@/api/administration/userRegistration';
import filterHelper from '@/mixins/filterHelper';

export default {
  name: 'SecondEquipmentPanelsFilter',
  props: {
    filterModel: { type: Object, default: () => {} },
  },
  mixins: [filterHelper],
  components: { RemoteSearchInput },
  data() {
    return {
      filter: [''],
      executions: [],
      organizations: [],
      techAdministrationOrganizations: [],
      techManagementOrganizations: [],
      dvOrganizations: [],
      duOrganizations: []
    };
  },
  async created() {
    if (UserSettingsService.has('secondEquipmentPanelsFilter_filter_open', this.user.id)) {
      this.filter = ['p1'];
    }
    const exec = await executions.getExecutionsWithPagination(1, 1000000, null, 'name', false);
    if (exec.data) {
      this.executions = exec.data.items;
    }
    const org = await regApi.getDispatchCenters();
    if (org.data) {
      this.organizations = org.data;
    }
    const techAdmOrgs = await organizationsApi.getOrganizationSearchByNameAndRole('Технологическое управление');
    if (techAdmOrgs.data) {
      this.techAdministrationOrganizations = techAdmOrgs.data;
    }
    const techManOrgs = await organizationsApi.getOrganizationSearchByNameAndRole('Технологическое ведение');
    if (techManOrgs.data) {
      this.techManagementOrganizations = techManOrgs.data;
    }
    const dvOrgs = await organizationsApi.getOrganizationSearchByNameAndRole('Диспетчерское ведение');
    if (dvOrgs.data) {
      this.dvOrganizations = dvOrgs.data;
    }
    const duOrgs = await organizationsApi.getOrganizationSearchByNameAndRole('Диспетчерское управление');
    if (duOrgs.data) {
      this.duOrganizations = duOrgs.data;
    }
  },
  computed: {
    ...mapGetters('dictionaries', ['baseVoltages', 'registrationGroups', 'decommissionedSEP', 'operationalZones']),
    ...mapGetters('identity', ['user']),
    sortedDescBaseVoltages() {
      // Добавляем новый элемент в массив baVo
      const baVoWithDefault = [...this.baseVoltages, { id: '', name: 'Без напряжения', code: 0 }];
      // Сортируем массив по убыванию свойства code
      return baVoWithDefault.sort((a, b) => b.code - a.code);
  }
  },
  methods: {
    handleChange(val) {
      if (val.find((el) => el === 'p1')) {
        UserSettingsService.set('secondEquipmentPanelsFilter_filter_open', true, this.user.id);
      } else {
        UserSettingsService.delete('secondEquipmentPanelsFilter_filter_open', this.user.id);
      }
      const objectLength = Object.keys(val);
      if (objectLength.length > 0) {
        // this.filterModel.isActive = true;
        this.$emit('active-change', true);
       } else {
         // this.filterModel.isActive = false;
        this.$emit('active-change', false);
      }
    },
    clearFilters() {
      this.$emit('clear-filters');
    }
  }
};
</script>

<style scoped lang="scss">
.filter--container{
   align-items: flex-start;
  span {
    min-width: 8rem;
  }
}
.big-label {
    span {
    min-width: 18rem;
  }
}
.middle-align{
  align-items:center;
}
</style>

