import axios from 'axios';

export default {
  getPrimaryEquipments(filterParam) {
    // filterParam.energyObjectIdFilter = filterParam.energyObjectIdFilter.length > 0 ? filterParam.energyObjectIdFilter.join('!;!') : null;
    return axios.post('/api/PrimaryEquipments/Get',  filterParam);
  },
  getPrimaryEquipmentsById(pageNumber, pageSize, primaryEquipmentId, secondEquipmentPanelId, nameFilter, psrTypeIdsFilter, energyObjectNameFilter, primaryEquipmentNameFilter, secondEquipmentPanelNameFilter, showAll) {
    return axios.get('/api/PrimaryEquipments/GetPrimaryEquipmentByEventId', {
      params: {
        pageNumber,
        pageSize,
        primaryEquipmentId,
        secondEquipmentPanelId,
        nameFilter,
        psrTypeIdsFilter: psrTypeIdsFilter.length > 0 ? psrTypeIdsFilter.join('!;!') : '',
        energyObjectNameFilter: energyObjectNameFilter.length > 0 ? energyObjectNameFilter.join('!;!') : '',
        primaryEquipmentNameFilter: primaryEquipmentNameFilter.length > 0 ? primaryEquipmentNameFilter.join('!;!') : '',
        secondEquipmentPanelNameFilter: secondEquipmentPanelNameFilter.length > 0 ? secondEquipmentPanelNameFilter.join('!;!') : '',
        showAll
      }
    });
  },
};   
