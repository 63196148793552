import axios from 'axios';

export default {
    getSecondEquipmentPanels(filterParams) {
        filterParams.eoIdFilter = filterParams.eoIdFilter.length > 0 ? filterParams.eoIdFilter.join('!;!') : '';
        filterParams.productAssetModelIdFilter = filterParams.productAssetModelIdFilter.length > 0 ? filterParams.productAssetModelIdFilter.join('!;!') : '';
        filterParams.duOrganizationName = filterParams.duOrganizationName.length > 0 ? filterParams.duOrganizationName.join('!;!') : '';
        filterParams.dvOrganizationName = filterParams.dvOrganizationName.length > 0 ? filterParams.dvOrganizationName.join('!;!') : '';
        return axios.post('/api/SecondEquipmentPanels/Get', filterParams);
    }
};
